interface EventInfo {
    eventId: number
    description: string
}

const swedishEventDescriptions = new Map<number, string>([
    [10001, "Ny modul skapad"],
    [10002, "Modulen uppdaterades"],
    [10003, "Modulen förfrågades att starta"],
    [10100, "Behörighet uppdaterad"],
    [10110, "Licens borttagen"],
    [10111, "Licens uppdaterad"],
    [10112, "Ny licens skapad"],
    [10120, "Grupp borttagen"],
    [10121, "Ny grupp skapad"],
    [10122, "Grupp uppdaterad"],
    [10200, "Användare tillagd"],
    [10300, "Servicekonto uppdaterades"],
    [10400, "Apptoken borttagen"],
    [10401, "Apptoken konfigurerades"],
    [10500, "Ny clientId/clientSecret skapad"],
    [10501, "Rensning/återställning av sftp-fingeravtrycket för apple school manager modulen utfördes"],
    [10600, "Primär skola för användare valdes"],
    [10601, "Primär skola för användare togs bort"],
    [10700, "Aktivitetskoppling uppdaterad"],
    [10800, "En koppling till ett Google konto för en person uppdaterades"],
    [10801, "En koppling till ett Google konto för en person inaktiverades"],
    [10802, "En koppling till ett Google konto för en person återställdes"],
    [10803, "En koppling till ett Google konto för en person skapades"],
]);

const dictionaries = new Map<string, Map<number, string>>([
    ['sv-SE', swedishEventDescriptions],
]);

export const translateEvent = (event: EventInfo, languageCode: string) => {
    return translateEventId(event.eventId, event.description, languageCode);
}

export const translateEventId = (eventId: number, fallback: string, languageCode: string) => {
    if (!languageCode) {
        throw new Error('No languageCode supplied, unable to translate event.')
    }

    var dictionary = dictionaries.get(languageCode);
    if (dictionary) {
        var description = dictionary.get(eventId);
        if (description) {
            return description;
        }
    }

    return fallback;
}